<template>
  <div>
    <div class="d-flex justify-start align-center">
      <div class="demo-space-x">
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <span class="d-block text-caption black--text">Sekolah</span>
        <span
          class="d-block text-subtitle-1 font-weight-bold black--text"
          style="line-height: 1.5rem !important"
        >
          {{ schoolName }}
        </span>
      </div>
    </div>
    <div class="mt-8">
      <v-row>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          cols="12"
        >
          <v-card class="pa-5">
            <div class="d-flex justify-start justify-md-center justify-xl-center justify-lg-center align-start">
              <div class="mr-6">
                <v-card
                  color="primary"
                  class="d-inline-block pa-3 mt-2 mb-4 rounded-circle"
                >
                  <div>
                    <v-img
                      :src="icons.iconUser"
                      width="28"
                    ></v-img>
                  </div>
                </v-card>
              </div>
              <div>
                <span class="d-block text-subtitle-1 black--text">Total Siswa</span>
                <span class="d-block text-h5 font-weight-bold black--text mb-3">{{ dataHead.student_count }}</span>
                <router-link
                  to="/student-headmaster"
                  class="text-decoration-none"
                >
                  <a class="text-subtitle-2">Lihat Detail</a>
                </router-link>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          cols="12"
        >
          <v-card class="pa-5">
            <div class="d-flex justify-start justify-md-center justify-xl-center justify-lg-center align-start">
              <div class="mr-6">
                <v-card
                  color="primary"
                  class="d-inline-block pa-3 mt-2 mb-4 rounded-circle"
                >
                  <div>
                    <v-img
                      :src="icons.iconUser"
                      width="28"
                    ></v-img>
                  </div>
                </v-card>
              </div>
              <div>
                <span class="d-block text-subtitle-1 black--text">Total Pegawai</span>
                <span class="d-block text-h5 font-weight-bold black--text mb-3">{{ dataHead.employee_count }}</span>
                <router-link
                  to="/functionary-headmaster"
                  class="text-decoration-none"
                >
                  <a class="text-subtitle-2">Lihat Detail</a>
                </router-link>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          cols="12"
        >
          <v-card class="pa-5">
            <div class="d-flex justify-start justify-md-center justify-xl-center justify-lg-center align-start">
              <div class="mr-6">
                <v-card
                  color="#F7B239"
                  class="d-inline-block pa-3 mt-2 mb-4 rounded-circle"
                >
                  <div>
                    <v-img
                      :src="icons.iconReceipt"
                      width="28"
                    ></v-img>
                  </div>
                </v-card>
              </div>
              <div>
                <span class="d-block text-subtitle-1 black--text">Bank Soal</span>
                <span class="d-block text-h5 font-weight-bold black--text mb-3">{{ dataHead.question_bank_count }}</span>
                <router-link
                  to="/bank-headmaster"
                  class="text-decoration-none"
                >
                  <a class="text-subtitle-2">Lihat Detail</a>
                </router-link>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          cols="12"
        >
          <v-card class="pa-5">
            <div class="d-flex justify-start justify-md-center justify-xl-center justify-lg-center align-start">
              <div class="mr-6">
                <v-card
                  color="#2197D8"
                  class="d-inline-block pa-3 mt-2 mb-4 rounded-circle"
                >
                  <div>
                    <v-img
                      :src="icons.iconMonitor"
                      width="28"
                    ></v-img>
                  </div>
                </v-card>
              </div>
              <div>
                <span class="d-block text-subtitle-1 black--text">CBT</span>
                <span class="d-block text-h5 font-weight-bold black--text mb-3">{{ dataHead.cbt_count }}</span>
                <router-link
                  to="/cbt/headmaster"
                  class="text-decoration-none"
                >
                  <a class="text-subtitle-2">Lihat Detail</a>
                </router-link>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="mt-4">
      <v-row>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
        >
          <v-card class="py-5 px-5 px-sm-10 px-md-10 px-lg-10 px-xl-10">
            <div class="d-flex justify-start justify-sm-space-between justify-md-space-between justify-lg-space-between justify-xl-space-between align-start align-sm-center align-md-center align-lg-center align-xl-center flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row">
              <div class="d-flex justify-start align-center">
                <div class="mr-6">
                  <v-card
                    color="#EE3E23"
                    class="d-inline-block pa-3 rounded-circle"
                  >
                    <div>
                      <v-img
                        :src="icons.iconDanger"
                        width="28"
                      ></v-img>
                    </div>
                  </v-card>
                </div>
                <div>
                  <span class="d-block text-subtitle-1 black--text">Pelanggaran Siswa</span>
                  <span class="d-block text-h5 font-weight-bold black--text">{{ dataHead.violation_student_count }}</span>
                </div>
              </div>
              <div>
                <div class="d-flex justify-start align-center">
                  <!-- start: not used -->
                  <div class="hidden-component d-block d-md-none">
                    <v-card
                      class="pa-3 rounded-circle"
                      style="visibility: hidden"
                    >
                      <div>
                        <v-img
                          width="28"
                        ></v-img>
                      </div>
                    </v-card>
                  </div>
                  <!-- end: not used -->
                  <div class="ml-6 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">
                    <router-link
                      to="/violation-student-headmaster"
                      class="text-decoration-none"
                    >
                      <a class="text-subtitle-2">Lihat Detail</a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
        >
          <v-card class="py-5 px-5 px-sm-10 px-md-10 px-lg-10 px-xl-10">
            <div class="d-flex justify-start justify-sm-space-between justify-md-space-between justify-lg-space-between justify-xl-space-between align-start align-sm-center align-md-center align-lg-center align-xl-center flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row">
              <div class="d-flex justify-start align-center">
                <div class="mr-6">
                  <v-card
                    color="#D498C4"
                    class="d-inline-block pa-3 rounded-circle"
                  >
                    <div>
                      <v-img
                        :src="icons.iconDanger"
                        width="28"
                      ></v-img>
                    </div>
                  </v-card>
                </div>
                <div>
                  <span class="d-block text-subtitle-1 black--text">Pelanggaran Pegawai</span>
                  <span class="d-block text-h5 font-weight-bold black--text">{{ dataHead.violation_employee_count }}</span>
                </div>
              </div>
              <div>
                <div class="d-flex justify-start align-center">
                  <!-- start: not used -->
                  <div class="hidden-component d-block d-md-none">
                    <v-card
                      class="pa-3 rounded-circle"
                      style="visibility: hidden"
                    >
                      <div>
                        <v-img
                          width="28"
                        ></v-img>
                      </div>
                    </v-card>
                  </div>
                  <!-- end: not used -->
                  <div class="ml-6 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">
                    <router-link
                      to="/violation-employee-headmaster"
                      class="text-decoration-none"
                    >
                      <a class="text-subtitle-2">Lihat Detail</a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="mt-4">
      <v-row>
        <v-col
          xl="5"
          lg="5"
          md="5"
          sm="12"
          cols="12"
        >
          <v-card class="pa-5">
            <v-row class="align-center">
              <v-col
                xl="5"
                lg="5"
                md="5"
                sm="12"
                cols="12"
              >
                <span class="text-subtitle-1 black--text font-weight-bold">Absensi Siswa</span>
              </v-col>
              <v-col
                xl="1"
                lg="1"
                md="1"
                sm="12"
                cols="12"
                class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
              ></v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                cols="12"
              >
                <div>
                  <v-autocomplete
                    :label="dateNow"
                    outlined
                    :items="date"
                    item-text="name"
                    item-value="id"
                    dense
                    class="filter"
                    @change="handleFilter($event)"
                  ></v-autocomplete>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <apex-chart-expense-ratio :count="count"></apex-chart-expense-ratio>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          xl="5"
          lg="5"
          md="5"
          sm="12"
          cols="12"
        >
          <v-card class="pa-5">
            <v-row class="align-center">
              <v-col
                xl="5"
                lg="5"
                md="5"
                sm="12"
                cols="12"
              >
                <span class="text-subtitle-1 black--text font-weight-bold">Absensi Pegawai</span>
              </v-col>
              <v-col
                xl="1"
                lg="1"
                md="1"
                sm="12"
                cols="12"
                class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
              ></v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                cols="12"
              >
                <div>
                  <v-autocomplete
                    label="April 2022"
                    outlined
                    dense
                    class="filter"
                  ></v-autocomplete>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <apex-chart-expense-ratio></apex-chart-expense-ratio>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import iconSchool from '@/assets/icons/courthouse.svg'
import iconBook from '@/assets/icons/white/book.svg'
import iconDanger from '@/assets/icons/white/danger.svg'
import iconDevices from '@/assets/icons/white/devices.svg'
import iconMessage from '@/assets/icons/white/messages-2.svg'
import iconMonitor from '@/assets/icons/white/monitor.svg'
import iconMusic from '@/assets/icons/white/music.svg'
import iconPlay from '@/assets/icons/white/play.svg'
import iconReceipt from '@/assets/icons/white/receipt-2.svg'
import iconUser from '@/assets/icons/white/user.svg'
import { mdiTownHall } from '@mdi/js'
import ApexChartExpenseRatio from './ApexChartExpenseRatio.vue'

export default {
  name: 'DashboardHeadmaster',
  components: {
    ApexChartExpenseRatio,
  },
  data() {
    return {
      count: [0, 0, 0, 0],
      icons: {
        iconMonitor,
        iconSchool,
        iconUser,
        iconDanger,
        iconBook,
        iconDevices,
        iconMessage,
        iconMusic,
        iconPlay,
        iconReceipt,
        mdiTownHall,
      },
      date: [
        {
          id: `01-${new Date().getFullYear()}`,
          name: `January ${new Date().getFullYear()}`,
        },
        {
          id: `02-${new Date().getFullYear()}`,
          name: `February ${new Date().getFullYear()}`,
        },
        {
          id: `03-${new Date().getFullYear()}`,
          name: `March ${new Date().getFullYear()}`,
        },
        {
          id: `04-${new Date().getFullYear()}`,
          name: `April ${new Date().getFullYear()}`,
        },
        {
          id: `05-${new Date().getFullYear()}`,
          name: `May ${new Date().getFullYear()}`,
        },
        {
          id: `06-${new Date().getFullYear()}`,
          name: `June ${new Date().getFullYear()}`,
        },
        {
          id: `07-${new Date().getFullYear()}`,
          name: `July ${new Date().getFullYear()}`,
        },
        {
          id: `08-${new Date().getFullYear()}`,
          name: `August ${new Date().getFullYear()}`,
        },
        {
          id: `09-${new Date().getFullYear()}`,
          name: `September ${new Date().getFullYear()}`,
        },
        {
          id: `10-${new Date().getFullYear()}`,
          name: `October ${new Date().getFullYear()}`,
        },
        {
          id: `11-${new Date().getFullYear()}`,
          name: `November ${new Date().getFullYear()}`,
        },
        {
          id: `12-${new Date().getFullYear()}`,
          name: `December ${new Date().getFullYear()}`,
        },
      ],
      dataHead: {},
      schoolName: '',
      paramsDate: '',
      dateNow: '',
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  async mounted() {
    this.schoolName = JSON.parse(localStorage.getItem('user')).user.details[0].school.name
    this.dateNow = `${this.monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
    this.checkFirstParams()
    await this.getDataCount({ date: this.paramsDate })
  },
  methods: {
    async handleFilter(id) {
      await this.getDataCount({ date: id })
    },
    checkFirstParams() {
      this.date.forEach(item => {
        if (item.name === this.dateNow) this.paramsDate = item.id
      })
    },
    async getDataCount(params) {
      await this.$services.ApiServices.list('employee', { ...params }).then(
        ({ data }) => {
          this.dataHead = { ...data.data }
          this.count = Object.values(data.data.absen)
        },
        err => console.error(err),
      )
    },
  },
}
</script>

<style>
.filter > .v-input__control > .v-text-field__details {
  display: none;
}
.filter > .v-input__control > .v-input__slot {
  margin: 0 !important;
}
</style>
